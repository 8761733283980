<template>
    <div class="page">
        <div class="header">
            <el-page-header @back="$router.go(-1)" content="人员变更"/>
        </div>
        <el-tabs v-model="activeName">
            <el-tab-pane label="我的申请" name="1" style="height: calc(100vh - 200px)">
                <data-form v-if="activeName=='1'" type="1"></data-form>
            </el-tab-pane>
            <el-tab-pane label="我的审批" name="2" style="height: calc(100vh - 200px)">
                <data-form v-if="activeName=='2'" type="2"></data-form>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
    import dataForm from "./widgets/dataform";
    export default {
        components: {
            dataForm,
        },
        name: 'transfer',
        data() {
            return {
                activeName:"1"
            }
        },
    }
</script>

<style lang="scss" scoped>
    .page {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .header {
        padding-bottom: 10px;

        .btns {
            float: right;
        }
    }

</style>
