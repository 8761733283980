<template>
    <el-dialog :visible="visible" width="40%" @close="handleClose" class="dialog" :title="title" @open="handelOpen">
        <el-form ref="form" :model="form" label-width="100px" size="mini">
            <el-row>
                <el-form-item label="身份证" prop="idCard" :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                    <el-row>
                        <el-input v-model="form.idCard"
                                  type="textarea"
                                  :rows="6"
                                  placeholder="一行一个身份证号"
                                  @change="handelInfo"></el-input>
                    </el-row>
                </el-form-item>

                <el-form-item label="" v-if="users.length">
                    <el-table :data="users" stripe size="small" width="100%" height="300px" border>
                        <el-table-column type="index" label="序号" align="center" width="55"/>
                        <el-table-column align="center" label="基本信息" width="">
                            <template slot-scope="scope">
                                <div>
                                    <span>{{ scope.row.name }}</span>
                                    <span v-if="scope.row.sex == '2'"> 女 </span>
                                    <span v-if="scope.row.sex == '1'"> 男 </span>
                                    <span v-if="scope.row.age">{{ scope.row.age }} 岁</span>
                                </div>
                                <div> {{scope.row.idCard}}</div>
                            </template>
                        </el-table-column>
                        <el-table-column align="center" label="联系方式" width="">
                            <template slot-scope="scope">
                                <div>{{ scope.row.companyName }}</div>
                                <div>{{ scope.row.mobile }}</div>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form-item>
                <el-form-item label="目的单位" prop="companyName"
                              :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                    <cascaderCompanyName v-model="form.companyName" ></cascaderCompanyName>
                </el-form-item>
                <el-form-item label="用户类型" prop="user_type"
                              :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                    <el-select v-model="form.user_type" placeholder="请选择" style="width:100%" clearable>
                        <el-option :label="item.name" :value="item.name" v-for="(item,index) in userTypeList"
                                   :key="index"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="联系方式" prop="contact">
                    <el-input v-model.trim="form.contact"></el-input>
                </el-form-item>
                <el-form-item label="申请说明" prop="reason">
                    <el-input v-model.trim="form.reason" type="textarea" :rows="2"></el-input>
                </el-form-item>
            </el-row>
            <el-form-item style="padding-top: 60px;text-align: right">
                <el-button type="primary" @click="onSubmit">确 定</el-button>
                <el-button @click="handleClose">取 消</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>

<script>
    export default {
        props: {
            visible: {
                default: false
            },
            form: {
                default: {}
            },
            title: {
                default: "单位变更"
            }
        },
        data() {
            return {
                userTypeList: [
                    {name: "劳务输出", id: 1},
                    {name: "工作调转", id: 2},
                ],
                users: []
            }
        },
        methods: {
            handelOpen(){
                this.users = []
            },
            async handelInfo(idCard) {
                try {
                    this.users = []
                    let url = `/company/user/info`
                    const resp = await this.$http.post(url, {idCard: idCard})
                    if (!resp.data.data) {
                        this.users = []
                        this.$message.error("请填入正确的身份证号！")
                    } else {
                        this.users = resp.data.data
                    }
                } catch (e) {
                    this.$message.error(e.message)
                }
            },
            async onSubmit() {
                var va = false
                this.$refs.form.validate((valid) => {
                    if (valid) {
                        va = true
                    }
                })
                if (!va) {
                    return
                }
                if (!this.users.length) {
                    this.$message.error("请填入正确的身份证号！")
                    return
                }
                try {
                    this.form.users = this.users
                    let url = `/company/audit/transfer`
                    const resp = await this.$http.post(url, this.form)
                    this.$message[resp.data.status](resp.data.msg)
                    if (resp.data.status == 'success') {
                        this.users = []
                    }
                } catch (e) {
                    this.$message.error(e.message)
                }
                this.$emit('update')
            },
            handleClose() {
                this.$emit('close')
            },
        }
    }
</script>

<style lang="scss" scoped>
    .dialog ::v-deep .el-dialog__body {
        padding: 10px 20px;
    }

    .tijian {
        border: 1px solid #c0c4cc;
        padding: 5px;
    }

    .title {
        display: flex;
        align-items: center;
        padding: 10px;

        .title-text {
            text-align: center;
            border-bottom: 1px solid #EBEEF5;
            border-top: 1px solid #EBEEF5;
            padding: 10px;
        }
    }

    ::v-deep .el-table .el-table__cell {
        padding: 6px 0;
    }

    .flex {
        display: flex;
    }

    .btn {
        justify-content: space-between;
        padding: 10px 0;
    }

    .border-bottom {
        border-bottom: 1px solid #EBEEF5;
    }

    .text-center {
        text-align: center;
        padding: 5px 0;
    }

    .align-items {
        align-items: center;
    }

    .text-group {
        display: grid;
        padding-right: 10px
    }
</style>
