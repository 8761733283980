<template>
    <div class="page">
        <div class="search">
            <div style="float: right" v-if="type=='1'">
                <el-button type="primary" size="mini" @click="handleTransferfer()" v-has="'transfer_apply'">单位变更
                </el-button>
            </div>
            <el-form :inline="true" :model="search" size="small" label-width="70px">
                <el-form-item label="搜索" label-width="60px">
                    <el-input placeholder="请输入内容" v-model="search.value" class="input-with-select" clearable>
                        <el-select v-model="search.key" slot="prepend" placeholder="请选择" style="width:80px">
                            <el-option label="名称" value="name"></el-option>
                            <el-option label="身份证" value="idCard"></el-option>
                        </el-select>
                    </el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" plain @click="getData" v-has="'transfer_check'">查询</el-button>
                    <el-button type="" plain @click="reset" v-has="'transfer_check'">重置</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="main-content">
            <div class="table-content">
                <el-table :data="table.data" stripe size="mini" border v-loading="loading" width="100%"
                          height="100%">
                    <el-table-column type="expand">
                        <template slot-scope="props">
                            <el-form label-position="left" inline  class="demo-table-expand" label-width="120px">
                                <el-row v-if="props.row.type=='transfer'">
                                    <el-table :data="props.row.source" stripe size="small" width="100%" height="300px" border>
                                        <el-table-column type="index" label="序号" align="center" width="55"/>
                                        <el-table-column align="center" label="基本信息" width="">
                                            <template slot-scope="scope">
                                                <div>
                                                    <span>{{ scope.row.name }}</span>
                                                    <span v-if="scope.row.sex == '2'"> 女 </span>
                                                    <span v-if="scope.row.sex == '1'"> 男 </span>
                                                    <span v-if="scope.row.age">{{ scope.row.age }} 岁</span>
                                                </div>
                                                <div> {{scope.row.idCard}}</div>
                                            </template>
                                        </el-table-column>
                                        <el-table-column align="center" label="联系方式" width="">
                                            <template slot-scope="scope">
                                                <div>{{ scope.row.companyName }}</div>
                                                <div>{{ scope.row.mobile }}</div>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                    <el-form-item label="目的单位">
                                        <span>{{ props.row.data && props.row.data.companyObj ? props.row.data.companyObj.name : '' }}</span>
                                    </el-form-item>
                                    <el-form-item label="目的单位等级">
                                        <el-tag v-if="props.row.data && props.row.data.companyObj && props.row.data.companyObj.level==1">一级单位</el-tag>
                                        <el-tag v-else-if="props.row.data && props.row.data.companyObj && props.row.data.companyObj.level==2">二级单位</el-tag>
                                        <el-tag v-else-if="props.row.data && props.row.data.companyObj && props.row.data.companyObj.level==3">三级单位</el-tag>
                                        <el-tag v-else-if="props.row.data && props.row.data.companyObj && props.row.data.companyObj.level==4">四级单位</el-tag>
                                        <el-tag v-else-if="props.row.data && props.row.data.companyObj && props.row.data.companyObj.level==5">五级单位</el-tag>
                                    </el-form-item>
                                    <el-form-item label="用户类型">
                                        <el-tag>{{props.row.data.user_type}}</el-tag>
                                    </el-form-item>
                                    <el-form-item label="联系方式">
                                        <span>{{ props.row.data ? props.row.data.contact : ''}}</span>
                                    </el-form-item>
                                    <el-form-item label="申请说明" v-if="props.row.data && props.row.data.note">
                                        <span>{{ props.row.data ? props.row.data.note : '' }}</span>
                                    </el-form-item>
                                </el-row>
                                <el-row v-else>
                                    <el-form-item label="基本信息">
                                        <div>
                                            <span>{{props.row.data.name }}</span>
                                            <span v-if="props.row.data.sex=='2'"> 女 </span>
                                            <span v-if="props.row.data.sex=='1'"> 男 </span>
                                            <span v-if="props.row.data.age">{{props.row.data.age }} 岁</span>
                                        </div>
                                        <div>
                                            <small> {{ isFullIdCard[props.$index] ? props.row.data.idCard :
                                                maskIdCardRegex(props.row.data.idCard) }}</small>
                                            <i class="el-icon-view" style="cursor: pointer;padding-left: 10px"
                                               @click="toggleIdCard(props.$index)"></i>
                                        </div>
                                    </el-form-item>
                                    <el-form-item label="联系方式">
                                        <div>{{ props.row.data.companyName }}</div>
                                        <div>{{ props.row.data.mobile }}</div>
                                    </el-form-item>
                                    <el-form-item label="工作区">
                                        <span>{{ props.row.data ? props.row.data.wpName : '' }}</span>
                                    </el-form-item>
                                </el-row>
                            </el-form>
                        </template>
                    </el-table-column>
                    <el-table-column type="index" label="序号" align="center"
                                     :index="(index) => { return (this.table.page - 1) * this.table.pageSize + index + 1 }"
                                     width="80"/>
                    <el-table-column align="center" label="操作类型">
                        <template slot-scope="scope">
                            <el-tag v-if="scope.row.type == 'transfer'" type="primary">单位变更</el-tag>
                            <el-tag v-if="scope.row.type == 'delete'" type="danger">删除</el-tag>
                        </template>
                    </el-table-column>

                    <el-table-column align="center" label="操作对象">
                        <template slot-scope="scope">
                            <div>人员</div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="申请人">
                        <template slot-scope="scope">
                            <div>{{ scope.row.user }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="申请时间">
                        <template slot-scope="scope">
                            <div>{{ scope.row.created_at | datetime}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="审核状态" width="140" prop="status">
                        <template slot-scope="scope">
                            <el-tag v-if="scope.row.status == '0'" type="primary">待审批</el-tag>
                            <el-tag v-if="scope.row.status == '1'" type="success">审批通过</el-tag>
                            <el-tag v-if="scope.row.status == '2'" type="danger">审批不通过</el-tag>
                            <el-tag v-if="scope.row.status == '3'" type="info">取消</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="审核时间">
                        <template slot-scope="scope">
                            <div>{{ scope.row.latest |datetime }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" width="200" label="操作">
                        <template slot-scope="scope">
                            <el-button type="text" size="mini" @click="audit(scope.row,'3')" v-if="type=='1' && scope.row.status=='0'">取 消
                            </el-button>
                            <el-button type="text" size="mini" @click="audit(scope.row,'3')" v-if="type=='1' && scope.row.type=='delete' && scope.row.status=='1'">取 消
                            </el-button>
                            <el-button type="text" size="mini" @click="audit(scope.row,'1')" v-if="type=='2' && scope.row.status=='0'">审核通过
                            </el-button>
                            <el-button type="text" size="mini" @click="audit(scope.row,'2')" v-if="type=='2' && scope.row.status=='0'">不通过
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <div class="pager">
            <el-pagination layout="total, prev, pager, next, sizes"
                           :page-sizes="[10, 20, 30, 50, 100, 200, 500, 1000]"
                           :current-page="table.page" :total="table.total" :page-size="table.pageSize"
                           @current-change="handlePageChange" @size-change="handlePageSizeChange"/>
        </div>
        <transfer-form :visible="transferVisible" :form="form" @close="transferVisible = false"
                       @update="getData"></transfer-form>
    </div>
</template>

<script>
    import data from '../data'
    import transferForm from "./transferform";

    const makeInitSearch = () => {
        return {
            key: "name",
            value: "",
        }
    }
    export default {
        name: 'data-form',
        props: {
            type: {
                default: "1"
            },
        },
        components: {
            transferForm
        },
        data() {
            return {
                transferVisible: false,
                form: {},
                isFullIdCard: {},
                loading: false,
                search: makeInitSearch(),
                table: data.table,
            }
        },
        methods: {
            handleTransferfer() {
                this.form = {}
                this.transferVisible = true
            },
            maskIdCardRegex(idCard) {
                if (!idCard) return;
                return idCard.replace(/^(.{4})(.*)(.{4})$/, function (match, p1, p2, p3) {
                    return p1 + p2.replace(/./g, '*') + p3;
                });
            },
            toggleIdCard(index) {
                this.$set(this.isFullIdCard, index, !this.isFullIdCard[index]);
            },
            async audit(row,status) {
                let text = status == '1' ? '通过审核' : '不通过审核'
                if(status == "3"){
                    text = "取消申请"
                }
                this.$confirm(`确认${text}吗？`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.loading = true
                    this.$http.post(`/company/audit/audit`,{status:status,id:row.id})
                        .then(resp => {
                            this.$message[resp.data.status](resp.data.msg)
                            if (resp.data.status == 'success') this.getData()
                        }).catch(err => {
                        this.$message.error(err.message)
                    }).finally(() => {
                        this.loading = false
                    })
                    this.loading = false
                }).catch(() => {
                });
            },
            reset() {
                this.search = makeInitSearch()
                setTimeout(() => {
                    this.getData()
                }, 0);
            },
            handlePageChange(e) {
                this.table.page = e
                this.getData()
            },
            handlePageSizeChange(e) {
                this.table.pageSize = e
                this.getData()
            },
            async getData() {
                this.loading = true
                this.transferVisible = false
                this.search.pageSize = this.table.pageSize
                let url = `/company/audit/audit_data?page=${ this.table.page}`
                if(this.type=="2"){
                    url = `/company/audit/approval?page=${ this.table.page}`
                }
                const resp = await this.$http.get(url, {params: this.search})
                if (!resp.data) {
                    this.$message.error(resp.data.msg)
                } else {
                    this.table.data = resp.data.data
                    this.table.total = resp.data.total
                }
                this.loading = false
            },
        },
        mounted() {
            this.getData()
        }
    }
</script>

<style lang="scss" scoped>
    .page {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .header {
        padding-bottom: 10px;

        .btns {
            float: right;
        }
    }

    .search {
        padding: 10px;
        background-color: #FAFAFA;
        border-top: 1px solid #EBEEF5;
        border-left: 1px solid #EBEEF5;
        border-right: 1px solid #EBEEF5;

        ::v-deep .el-form-item {
            padding: 5px 0 !important;
            margin-bottom: 5px !important;
        }

        .btns {
            float: right;
        }
    }

    .main-content {
        flex: 1;
        position: relative;
    }

    .table-content {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .pager {
        padding-top: 10px;
        text-align: center;
    }

    .filter-tree {
        height: calc(100vh - 260px);
        overflow: auto;
    }
    ::v-deep .demo-table-expand {
        font-size: 0;
        padding-left: 100px;
    }
    ::v-deep .demo-table-expand label {
        width: 90px;
        color: #99a9bf;
    }
    .demo-table-expand ::v-deep .el-form-item {
        margin-right: 0;
        margin-bottom: 0;
        width: 50%;
    }
</style>
